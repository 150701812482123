<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <h5>Your Channel</h5>
        <hr/>
        <b-row>
          <b-col v-for="(ch, i) in channelList" :key="i" sm="6" md="3">
            <div @click="setActiveChannel(ch)" class="channel-card text-center" style="cursor: pointer">
              <h5>
                <a class="cursor-pointer"><span :class="ch.type"><i :class="ch.icon"></i></span>{{ ch.display_name }}</a>
              </h5>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import channelsAPI from '@/api/channels';
import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'ReportSession',
  metaInfo: {
    title: 'Report Sessions',
  },
  data() {
    return {
      loaderStack: 0,
      loader: null,
      channelList: [],
      search: '',
      availableChannel: [
        { name: 'WhatsApp', icon: 'mdi mdi-whatsapp', value: 'whatsapp' },
        { name: 'Telegram', icon: 'mdi mdi-telegram', value: 'telegram' },
        { name: 'SMS', icon: 'mdi mdi-mailbox', value: 'sms' },
        { name: 'Email', icon: 'mdi mdi-email', value: 'email' },
        { name: 'FB Messenger', icon: 'mdi mdi-facebook-messenger', value: 'fb-messenger' },
        { name: 'WhatsApp Personal', icon: 'mdi mdi-whatsapp', value: 'whatsapp_personal' },
        { name: 'WebChat', icon: 'mdi mdi-message-text', value: 'webchat' },
        { name: 'Instagram', icon: 'mdi mdi-instagram', value: 'instagram' },
      ],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    if (!this.activeWorkspace._id) {
      this.$router.push('/workspaces');
      return;
    }
    this.getChannelList();
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async getChannelList() {
      this.showLoader();
      await channelsAPI.getList(this.activeWorkspace._id, this.page, this.search)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.channelList = res.data.channels.map((v) => {
            const key = this.availableChannel.find((k) => k.value === v.type);
            if (key) {
              v.icon = key.icon;
              v.display_name = key.name;
            } else {
              v.icon = '';
              v.display_name = v.name;
            }
            return v;
          });
        })
        .catch((err) => {
          console.error(err);
        });
      this.hideLoader();
    },
    async setActiveChannel(item) {
      if (item.type !== 'whatsapp') {
        this.$router.push({
          name: 'Coming Soon',
        });
        return;
      }
      this.$router.push({
        name: 'Session Reports Detail',
        params: {
          channel_id: item._id,
        },
      });
    },
  },
};
</script>
<style>
/*---------------------------*/
.channel-card{
  padding: 20px;
  box-shadow: 0 4px 9px rgba(0,0,0,0.08);
  margin-top: 35px;
  padding-top: 55px;
  position: relative;
  border: 1px solid #f4f4f4;
}
.channel-card a{
  color: #333;
}
.channel-card span{
  width: 70px;
  height: 70px;
  font-size: 36px;
  padding: 10px;
  color: #fff;
  text-align: center;
  padding-top: 12px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  border-radius: 8px;
}
</style>
